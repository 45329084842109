<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item">
            <form-select
              v-model="filters.status"
              name="status"
              icon="description"
              label="Status"
              :options="statuses"
            />
          </div>
          <div class="md-layout-item">
            <md-button
              class="md-success w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getPackages"
              @onShowDetails="onShowDetails"
              @onShowPayments="onShowPayments"
              @onApprove="onApprove"
              @onDeny="onDeny"
              @onToProfile="onToProfile"
              @onDownloadInvoices="onDownloadInvoices"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormSelect } from '@/components/Inputs';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormSelect,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme.programme_id',
            mask: 'Programme ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'programme.student.student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'programme.student.first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'programme.student.last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'details.package_status',
            mask: 'status',
            sortable: true,
          },
          {
            title: 'details.total',
            mask: 'total',
            pre: '€',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'To Student Profile',
            callback: 'onToProfile',
            icon: 'person',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Approve Package',
            callback: 'onApprove',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deny Package',
            callback: 'onDeny',
            icon: 'thumb_down_alt',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Payments',
            callback: 'onShowPayments',
            icon: 'euro',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Download Invoices',
            callback: 'onDownloadInvoices',
            icon: 'get_app',
          },
        ],
        values: {},
      },
      statuses: [
        {
          id: 'all',
          name: 'All Statuses',
        },
        {
          id: 'Void',
          name: 'Void',
        },
        {
          id: 'Paid',
          name: 'Paid',
        },
        {
          id: 'Rejected',
          name: 'Rejected',
        },
        {
          id: 'Unpaid',
          name: 'Unpaid',
        },
        {
          id: 'Overdue',
          name: 'Overdue',
        },
        {
          id: 'Waiting for Approval',
          name: 'Waiting for Approval',
        },
      ],
      filters: {
        status: 'all',
      },
      loading: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getPackages(params) {
      const parameters = params;
      parameters.filters = {
        custom: this.filters,
      };

      this.request(this.$API.PACKAGES.paginableUrl(parameters), 'get', null, ({ data }) => {
        const aux = data;
        aux.data.map((x) => {
          const y = x;
          y.onApprove = x.details.package_status === 'Waiting for Approval';
          y.onDeny = x.details.package_status === 'Waiting for Approval';
          return y;
        });
        this.vTable.values = aux;
      });
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'PackageDetails', params: { id: item.package_id } }, isMiddleClick);
    },
    onShowPayments(item, isMiddleClick) {
      this.openLink({ name: 'PackagePayments', params: { id: item.package_id } }, isMiddleClick);
    },
    onToProfile(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.programme.student_id },
      }, isMiddleClick);
    },
    onApprove(item) {
      this.fireConfirm(
        'Approving Package',
        'Are you sure you want to Approve this Package?',
      ).then(() => {
        if (this.AutoInvoiceActive) {
          this.fireConfirm(
            'Sending Invoice Email',
            'Do you want to send the Invoice Email?',
          ).then(() => {
            this.approvePackage(item.package_id, true);
          }).catch(() => {
            this.approvePackage(item.package_id, false);
          });
        } else {
          this.approvePackage(item.package_id, false);
        }
      });
    },
    approvePackage(id, with_email = false) {
      this.loading = true;
      this.request(`packages/${id}/approve`, 'put', {
        approved: true,
        with_email,
      }, () => {
        this.fireSuccess('Package approved succesfully!');
        // Reset Table after response
        this.$refs.vtable.init();
      }, () => {
        this.loading = false;
      });
    },
    onDeny(item) {
      this.fireConfirm(
        'Denying Package',
        'Are you sure you want to Deny this Package?',
      ).then(() => {
        this.loading = true;
        this.request(`packages/${item.package_id}/approve`, 'put', {
          approved: false,
        }, () => {
          this.fireSuccess('Package denied succesfully!');
          // Reset Table after response
          this.$refs.vtable.init();
        }, () => {
          this.loading = false;
        });
      });
    },
    downloadPaymentInvoice(item, studentNumber) {
      return new Promise((res) => {
        this.request(this.$API.SCHEDULED_PAYMENTS + item.package_payment_id, 'download', null, ({ data }) => {
          const filename = `${studentNumber}_${moment(item.payment_date).format('DD-MM-YYYY')}.pdf`;
          downloadFile(data, filename);
        }, () => {
          res();
        });
      });
    },
    async onDownloadInvoices(item) {
      await Promise.all(item.package_payments.map(async (payment) => {
        await this.downloadPaymentInvoice(payment, item.programme.student.student_number);
      }));
    },
    onFilter() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style scoped>
</style>
